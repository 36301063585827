
$(function () {
  var ww = $(window).width();
  if(ww < 1024){
    $(".menu .hd").click(function(){
      $(this).siblings(".focus").find("div").slideToggle();
      $(this).siblings().removeClass("focus");    
      $(this).toggleClass("focus");
      $(this).find("div").slideToggle();
    });
  }  

  $(".switch").click(function(){
    $(this).toggleClass("focus");
    $(".menu").toggleClass("focus");
  });

  $(window).resize(function(){
    var ww = $(window).width();
    if(ww > 1023){
      $(".switch").removeClass("focus");
      $(".menu").removeClass("focus");
      $(".menu .hd").removeClass("focus");
      $(".menu .hd div").fadeOut();
    }
  });

  $('.case').slick();

  $( "img" ).lazyload();

});

